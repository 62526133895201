@import '../../../assets/scss/1-settings/1-settings.scss';
@import '../../../assets/scss/2-tools/2-tools.scss';

.sz-aside {
  top: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0 7px 10px rgba(0, 0, 0, 0.27);
  transition: width 0.5s ease;
  @at-root #{&}__item {
    position: relative;
    width: 100%;
    height: 62px;
    padding: 0 $default-spacing * 3;
    transition: 0.5s ease;
    &:hover {
      background: var(--light-blue);
    }
    &.active,
    &.active:hover {
      background: var(--primary);
      color: var(--secondary);
    }
    &.active:after {
      content: '\f116';
      font-family: 'sz-icons-bold';
      display: flex;
      align-items: center;
      position: absolute;
      right: $default-spacing * 1.5;
      font-size: $font-size-base;
      top: 0;
      bottom: 0;
    }
  }
  @at-root #{&}__item--title {
    padding-left: $default-spacing * 2;
  }
  @at-root #{&}__item--icon {
    font-size: 1.5rem;
  }
}
.sz-aside.sz-aside-enter-done {
  width: 100%;
  transition: width 0.5s ease;
  .sz-aside__item {
    opacity: 1;
    padding: 0 $default-spacing * 3;
    transition: 0.5s ease;
  }
}
.sz-aside.sz-aside-exit-done {
  width: 0;
  transition: width 0.5s ease;
  .sz-aside__item {
    opacity: 0;
    padding-left: $default-spacing * 1.5;
    transition: 0.5s ease;
    &.active:after {
      content: none;
    }
  }
}
@media screen and (min-width: 768px) {
  .sz-aside {
    width: 200px;
  }
  .sz-aside.sz-aside-enter-done {
    width: 200px;
  }
  .sz-aside.sz-aside-exit-done {
    width: 50px;
    .sz-aside__item {
      opacity: 1;
      transition: 0.5s ease;
    }
    .sz-aside__item--title {
      opacity: 0;
      transition: opacity 0.25s ease;
    }
  }
}
