@import '~bootstrap/scss/mixins';

.cursor-pointer {
  cursor: pointer;
}
.font-family-bold {
  font-family: $headings-font-family;
  font-weight: 900;
}
.smaller {
  font-size: 0.625em;
}
.small {
  font-size: 0.75em;
}
.normal {
  font-size: 1em;
}
.big {
  font-size: 3.429em;
}
.sz-big-icon-size {
  font-size: 9rem;
}
.sz-line-height-normal {
  line-height: 1;
}
.sz-line-height-small {
  line-height: 0.6;
}
.sz-text-underline {
  text-decoration: underline;
}
.sz-underline {
  &::after {
    content: '';
    display: block;
    width: 40px;
    height: 4px;
    margin: 8px 0;
    background-color: var(--secondary);
  }
}
.sz-border-2 {
  border: 2px solid;
}
.sz-left-border {
  &-light::after,
  &::after {
    content: '';
    width: 4px;
    border-radius: 4px;
    position: absolute;
    left: 4px;
    background: var(--secondary);
  }
  &::after {
    top: 4px;
    bottom: 4px;
  }
  &-light::after {
    top: 3px;
    bottom: 3px;
    opacity: 0.3;
  }
}
.text-muted {
  color: var(--gray-light) !important;
}
.letter-spacing {
  letter-spacing: 1px;
}
@each $color, $value in $theme-colors {
  .bg-#{$color} {
    background-color: var(--#{$color}) !important;
  }
  .text-#{$color} {
    color: var(--#{$color}) !important;
  }
  .btn-#{$color} {
    background: linear-gradient(
        to left,
        var(--#{light-gradient}),
        var(--#{$color})
      )
      repeat-x;
    border-color: var(--#{$color});
    &:hover {
      background: linear-gradient(
          to left,
          var(--#{light-gradient}),
          var(--#{$color})
        )
        repeat-x;
      border-color: var(--#{$color});
    }
    &.disabled,
    &:disabled {
      opacity: 1;
    }
  }
  .border-#{$color} {
    border-color: var(--#{$color}) !important;
  }
}
