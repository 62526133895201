@import '../../../assets/scss/1-settings/1-settings.scss';
@import '../../../assets/scss/2-tools/2-tools.scss';

.sz-header-stepper {
  height: 65px;
  margin-bottom: 1px;
  @at-root #{&}__step {
    padding-left: $default-spacing * 3;
    line-height: 1;
    color: #b5b9c2;
    span {
      font-size: $font-size-base * 1.15;
    }
    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 4px;
      bottom: 0;
      left: 0;
      background: var(--secondary);
      transition: 0.3s ease;
    }
    &.active {
      color: #000d42;
    }
    &.underline {
      &::after {
        width: 100%;
      }
    }
  }
  .simple-title {
    top: 0;
  }
  .stepper-bar {
    width: 75px;
    height: 7px;
  }
}
.sz-body-stepper {
  padding: $default-spacing * 1.875 $default-spacing * 2;
  @at-root #{&}__step {
    display: none;
    &.active {
      display: block;
    }
  }
}

@media screen and (min-width: 768px) {
  .sz-body-stepper {
    padding: $default-spacing * 3.75 $default-spacing * 5;
  }
}
