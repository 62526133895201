@import '../../../assets/scss/1-settings/1-settings.scss';

.react-date-picker {
  height: 58px;
  width: 50%;
  @at-root #{&}__wrapper {
    border: 2px solid var(--light);
    width: 100%;
    &::before {
      content: '\f11c';
      font-family: 'sz-icons-bold';
      align-items: center;
      padding: 0 12px;
      display: flex;
    }
    .react-date-picker__calendar-button {
      display: flex;
    }
  }
  &:last-child {
    > div {
      border-left: 0px;
    }
  }
  input {
    color: var(--primary);
    font-family: $font-family-sans-serif;
    font-size: 1em;
    &:focus {
      outline: none;
    }
  }
  @at-root #{&}__button {
    padding: 4px 15px;
  }
  @at-root #{&}__inputGroup {
    font-family: $headings-font-family;
    font-weight: $font-weight-bold;
  }
  @at-root #{&}__calendar {
    z-index: 6;
    .react-calendar {
      padding: $default-spacing $default-spacing $default-spacing * 3;
      border-color: var(--grey);
      &:after,
      &:before {
        bottom: 99.8%;
        left: 50%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      &:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #fff;
        border-width: 10px;
        margin-left: -10px;
      }
      &:before {
        border-color: rgba(204, 204, 204, 0);
        border-bottom-color: var(--grey);
        border-width: 11px;
        margin-left: -11px;
      }
    }
  }
  .react-calendar__navigation__next-button,
  .react-calendar__navigation__prev-button,
  .react-calendar__tile--active,
  .react-calendar__tile--hasActive {
    background: var(--secondary);
  }
  .react-calendar__month-view__days__day--weekend {
    color: var(--grey);
  }
  .react-calendar__month-view__weekdays__weekday {
    visibility: hidden;
    color: var(--primary);
    &::first-letter {
      visibility: visible;
    }
    abbr {
      text-decoration: none;
      padding-left: $default-spacing * 2;
    }
  }
  .react-calendar__month-view {
    padding: 0 $default-spacing * 2;
    font-family: $font-family-sans-serif;
  }
  .react-calendar__navigation__next2-button,
  .react-calendar__navigation__prev2-button {
    display: none;
  }
}
