@import '../../../assets/scss/1-settings/1-settings.scss';
@import '../../../assets/scss/2-tools/2-tools.scss';

.sz-btn {
  @include gradient-x(
    $start-color: var(--light-gradient),
    $end-color: var(--secondary),
    $start-percent: 0%,
    $end-percent: 100%
  );
  width: 100%;
  &:not(:disabled)::after {
    content: '';
    position: absolute;
    background-color: rgba(58, 62, 70, 0.2);
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: scale(0.35, 3.5);
    border-radius: 80%;
    transition: 0.25s ease-in;
    opacity: 0;
  }
  &:hover {
    &::after {
      opacity: 1;
      border-radius: 0;
      transform: scale(1, 1);
    }
  }
  &:focus {
    box-shadow: none;
  }
  &:active {
    background-color: var(--secondary) !important;
  }
  &[disabled] {
    background: var(--btn-disable) !important;
    color: var(--primary);
    font-weight: bold;
  }
}
.sz-btn-outline {
  width: 100%;
  background: transparent !important;
  &:hover {
    background: var(--secondary) !important;
  }
  &:focus {
    box-shadow: none;
  }
}
