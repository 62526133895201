.Sz-pagination {
  .page-item {
    .page-link {
      padding: 0;
      background: transparent;
      border: none;
      margin: 0 5px;
      line-height: 27px;
      color: var(--btn-disable);
      &:focus {
        box-shadow: none;
      }
    }
    &.active {
      .page-link {
        background: transparent;
        border: none;
        font-weight: bold;
        color: var(--primary);
      }
    }
  }
}
