@import '../../../assets/scss/1-settings/1-settings.scss';
@import '../../../assets/scss/2-tools/2-tools.scss';

.sz-modal {
  .modal-content {
    padding: 2rem 2.5rem;
  }
  .close:not(:disabled) {
    color: var(--primary);
    opacity: 1;
    font-size: 2.5rem;
    padding: 0.25rem 1rem;
    &:hover,
    &:focus {
      opacity: 1;
      outline: none;
    }
  }
}
