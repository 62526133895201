@import '../../../assets/scss/1-settings/1-settings.scss';
@import '../../../assets/scss/2-tools/2-tools.scss';

.sz-dropdown {
  .dropdown-basic.dropdown-toggle,
  .dropdown-basic.dropdown-toggle:focus,
  .dropdown-basic.dropdown-toggle:active,
  .dropdown-basic.dropdown-toggle:focus:active {
    background: transparent;
    box-shadow: none;
    &:after {
      border: 0;
    }
  }
  .dropdown-menu {
    top: 10px;
    right: 0;
    &:after,
    &:before {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #fff;
      border-width: 10px;
      margin-left: -10px;
    }
    &:before {
      border-color: rgba(204, 204, 204, 0);
      border-bottom-color: var(--grey);
      border-width: 11px;
      margin-left: -11px;
    }
    a {
      &:last-child {
        border: 0 !important;
      }
      &:active {
        background: transparent !important;
        color: var(--primary);
      }
      i {
        font-size: 1.5rem;
      }
    }
  }
  @at-root #{&}__icon {
    font-size: 1.3rem;
  }
}
.scrollContainer {
  max-height: 250px;
}
/* CUSTOM SCROLL CSS*/
.rcs-custom-scroll {
  .rcs-custom-scrollbar {
    height: 250px;
    width: 2px;
    background: var(--light);
  }
  .rcs-inner-handle {
    background-color: var(--primary);
  }
}
