@import '../../../assets/scss/1-settings/1-settings.scss';

.sz-checkbox {
  padding: 0;
  .sz-custom-checkbox {
    opacity: 0;
  }
  span.content {
    $box-target-size: 17px;
    $box-padding: 1px;
    $box-width: $box-target-size - $box-padding;
    border: 2px solid var(--primary);
    padding: $box-padding;
    width: $box-width;
    height: $box-width;
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      border-radius: 50%;
      opacity: 0;
      transition: 0.3s ease;
    }
  }
  &.bgWarning {
    span.content {
      &::before {
        background: var(--warning);
      }
    }
  }
  &.bgSecondary {
    span.content {
      &::before {
        background: var(--secondary);
      }
    }
  }
  &.bgWarning.checked {
    span.content {
      &::before {
        opacity: 1;
      }
    }
  }
  &.bgSecondary.checked {
    span.content {
      &::before {
        opacity: 1;
      }
    }
  }
}
.custom-control-label::before {
  border: 2px solid;
  border-radius: 0 !important;
}
