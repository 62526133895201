.sz-input-tooltip {
  &.show {
    opacity: 1;
  }
  .tooltip-inner {
    max-width: 250px;
    padding: 0.7rem 1rem;
    border-radius: 0;
    background-color: var(--primary);
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
  }
}
