@import '../../../assets/scss/1-settings/1-settings.scss';
@import '../../../assets/scss/2-tools/2-tools.scss';
@import '../../../assets/scss/7-utilities/7-utilities.scss';

.sz-card-collapse {
  @at-root #{&}__header {
    padding: 1.15rem;
    padding-left: 1.2rem;
    .card-header {
      cursor: pointer;
    }
  }
}
