@import '../../../assets/scss/1-settings/1-settings.scss';
@import '../../../assets/scss/2-tools/2-tools.scss';

.sz-label-input {
  line-height: 1;
  &.required:after {
    content: '*';
    position: absolute;
    color: var(--danger);
    right: 0;
  }
}
.sz-form-group {
  @at-root #{&}__sz-input-text {
    border: none;
    height: 58px;
    transition: none;
    box-shadow: none;
    &::placeholder {
      color: var(--placeholder);
      font-style: italic;
      font-weight: $font-weight-normal;
      font-family: $font-family-sans-serif;
    }
    &:focus {
      border: $border-width solid var(--secondary);
      background: #ffffff;
      box-shadow: none;
      &::placeholder {
        color: var(--placeholder);
      }
    }
  }
  @at-root #{&}__sz-input-text--checked {
    border: $border-width solid var(--secondary);
    font-style: normal;
  }
  @at-root #{&}__sz-input-text--error {
    border: $border-width solid var(--danger);
    &::placeholder {
      color: var(--danger);
      font-style: normal;
    }
    &:focus {
      &::placeholder {
        color: var(--placeholder);
      }
    }
  }
  @at-root #{&}__input-group-icon {
    z-index: 5;
    left: 0;
    top: 0;
    bottom: 0;
    width: 45px;
    font-size: $font-size-base * 1.28;
  }
  @at-root #{&}__input-group-icon-clear {
    z-index: 5;
    right: 0;
    top: 0;
    bottom: 0;
    width: 45px;
    font-size: $font-size-base * 1.2;
    cursor: pointer;
  }
  @at-root #{&}__input-group-icon-valid {
    z-index: 5;
    right: 0;
    top: 0;
    bottom: 0;
    width: 58px;
  }
  @at-root #{&}__input-group-icon-info {
    z-index: 5;
    right: 2px;
    bottom: 2px;
    top: 2px;
    width: 45px;
    font-size: $font-size-base * 1.5;
  }
}
