// Overwrite Bootstrap's variables here
// You can find them in node_modules/bootstrap/scss/_variables.scss
// Copy the variables you need into this file, don't modify files under node_modules/

// Changing the body background and text
$body-bg: #f7f7f7;
$body-color: var(--primary);

// Enabling shadows and gradients
$enable-shadows: true;
$enable-gradients: true;

// Changing the border width and radius of buttons
$border-width: 2px;
$border-radius: 0px;
$border-radius-circle: 50px;

// Changing the input border width
$input-border-width: 1px;
// Changing the table variables
$table-border-width: 1px;
$table-border-color: $body-bg;
// Default spacing
$default-spacing: 8px;

// Font base
$font-family-sans-serif: 'DINPro-Medium';
$headings-font-family: 'DINPro-Bold';
$headings-font-weight: 900;
$font-size-base: 0.875rem;
$h1-font-size: 1rem;
$h2-font-size: $font-size-base;
$h3-font-size: $font-size-base;

$font-weight-normal: 500 !default;
$font-weight-bold: 900 !default;

// Alert bg
$alert-bg-level: 0;

// Changing the default theme colors created by bootstrap
$primary: #030f40;
$secondary: #a3d049;
$success: #2bc550;
$info: #495dff;
$warning: #ef8143;
$danger: #eb4a65;
$light: #eaedf4;
$dark: #0f1319;
$grey: #c1c4cc;

// Adding (!) an additional theme color (ex. classes btn-suez, bg-suez)
$theme-colors: (
  'toolbar': #030f40,
  'light-gradient': lighten($secondary, 5%),
  'btn-disable': #cbced5,
  'gray-light': #8a9396,
  'placeholder': #7c818b,
  'light-green': #d5f02d,
  'light-blue': #f4f9ff
);

@-moz-document url-prefix() {
  h1,
  h2,
  h3,
  h4,
  .font-family-bold {
    font-family: 'DINPro-Medium';
  }
}
