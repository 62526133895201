.nav {
  &.nav-tabs {
    font-size: 18px;
    border: 0px;
    margin-bottom: 20px;
    .nav-link {
      border: 0px;
      color: var(--gray-light);
      &.active {
        border: 0px;
        border-bottom: 2px solid var(--dark);
        color: var(--dark);
      }
    }
  }
}
