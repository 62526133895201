@import '../../../assets/scss/1-settings/1-settings';
@import '../../../assets/scss/7-utilities/7-utilities';

.sz-table {
  tr td:first-child,
  tr th:first-child {
    padding-left: 1.2rem;
  }
  th:focus {
    outline: none;
  }
  thead tr th:first-child,
  tbody tr td:first-child {
    position: relative;
    @extend .sz-left-border-light;
  }
  @at-root #{&} #{&}-header {
    height: 60px;
    th {
      font-weight: $font-weight-normal;
      padding: 0;
      padding-left: 0.75rem;
      vertical-align: middle;
      border: none;
    }
  }
}
